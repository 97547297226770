import React, {Component, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { getFamily,addEventToMDB,getEvents,removeEvent,editEvent,editCalEvent } from '../actions';
import styled from "@emotion/styled";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Switch from "react-switch";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { Divider } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const projectFont = '"Slabo 27px", serif'
// const projectFont = '"BioRhyme", serif'

// add styles as css
const StyleWrapper = styled.div`
.fc-header-toolbar {
  margin-top: 20px;
},
.fc-button.fc-prev-button, .fc-button.fc-next-button, .fc-button.fc-button-primary,.fc-button.fc-button-primary.fc-button-active{
    background: white;
    background-image: none;
    color: black;
    margin-left: 0;
},
.fc-button.fc-today-button {
    border-radius: 0;
    height: 37px;
    border-color: rgb(242,242,242) !important;
},
.fc-button.fc-next-button{
  border-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 37px;
  background-color: rgb(242,242,242);
  border-color: rgb(242,242,242) !important;
}
.fc-button.fc-prev-button{
  border-radius: 0;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 37px;
  background-color: rgb(242,242,242);
  border-color: rgb(242,242,242) !important;
}
.fc-timeGridWeek-button{
  border-radius: 0;
  height: 37px;
  border-color: rgb(242,242,242) !important;
},
.fc .fc-button:focus {
  box-shadow: none !important;
},
.fc-dayGridMonth-button{
  border-radius: 0;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 37px;
  background: rgb(242,242,242) !important;
  border-color: rgb(242,242,242) !important;
}
.fc-timeGridDay-button{
  border-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 37px;
  background: rgb(242,242,242) !important;
  border-color: rgb(242,242,242) !important;
}
.fc-icon.fc-icon-chevron-right,.fc-icon.fc-icon-chevron-left{
    color: black;
},
.fc-daygrid-day-number,.fc-col-header-cell-cushion{
    color: black;
},
.fc-day.fc-day-today {
    background: none !important
},
.fc-day-today .fc-daygrid-day-frame {
    border: solid rgb(255,0,0) !important;
} ,
.fc-popover.fc-more-popover.fc-day-today {
    background: white !important;
    border: solid rgb(255,0,0) !important;
} ,
.fc-toolbar-title, .fc-daygrid-day-number,.fc-col-header-cell-cushion,.fc-button{
    font-family: ${projectFont}
},
.fc-col-header-cell-cushion {
    font-size: 22px;
},
.fc-daygrid-day-number {
    font-size: 22px;
},
.fc-toolbar-title{
    font-size: 48px;
},
.fc-event {
    cursor: pointer;
},
`



const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme?.palette?.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme?.palette?.mode === 'light'
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme?.palette?.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme?.transitions?.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const EventItem = ({ info }) => {
    const { event } = info;
    return (
      <div>
        <p>{event.title}</p>
      </div>
    );
  };



const Calendar = ({family,getFamily,masterevents,getEvents,addEventToMDB,removeEvent,editEvent,editCalEvent}) => {

    const [events, setEvents] = useState([]);
    const [openaddevent, setOpenaddevent] = useState(false);
    const [opendetails, setOpendetails] = useState(false);
    const [openrecurring, setOpenrecurring] = useState(false);
    const [eventTitle, setEventtitle] = useState('');
    const [eventNotes, setEventnotes] = useState('');
    const [eventLocation, setEventlocation] = useState('');
    const [eventStartDate, setEventStartDate] = useState(moment());
    const [eventStartTime, setEventStartTime] = useState(moment());
    const [eventEndDate, setEventEndDate] = useState(moment());
    const [eventEndTime, setEventEndTime] = useState(moment());
    const [eventID, setEventid] = useState('')
    const [viewEventTitle, setViewEventTitle] = useState('');
    const [viewEventStart, setViewEventStart] = useState('');
    const [viewEventEnd, setViewEventEnd] = useState('');
    const [viewEventAllday, setViewEventAllday] = useState(false)
    const [viewEventLocation, setViewEventLocation] = useState('');
    const [viewEventNotes, setViewEventNotes] = useState('');
    const [viewEventId, setViewEventId] = useState('');
    const [viewEventCalId, setViewEventCalId] = useState('');
    const [viewEventColor, setViewEventColor] = useState('');
    const [viewEventName, setViewEventName] = useState('')
    const [eventFamName, setEventFamName] = useState('')
    const [eventColor, setEventColor] = useState('')
    const [eventCalId, setEventCalId] = useState('')
    const [filterName, setFilterName] = React.useState('');
    const [filterList, setFilterList] = useState([]);
    const [filterColor, setFilterColor] = useState('');
    const [checked, setChecked] = useState(false);
    const [allday, setAllday] = useState(false);
    const [showRecurring, setShowRecurring] = useState(false);
    const [sunday, setSunday] = useState(false);
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday2, setSunday2] = useState(false);
    const [monday2, setMonday2] = useState(false);
    const [tuesday2, setTuesday2] = useState(false);
    const [wednesday2, setWednesday2] = useState(false);
    const [thursday2, setThursday2] = useState(false);
    const [friday2, setFriday2] = useState(false);
    const [saturday2, setSaturday2] = useState(false);
    const [recurFreq, setRecurFreq] = useState('first')
    const [rf, setRf] = useState('1')
    const [rd, setRd] = useState('1')
    const [recurYearCheck, setRecurYearCheck] = useState(false);
    const [recurWeekCheck, setRecurWeekCheck] = useState(false);
    const [recurDayCheck, setRecurDayCheck] = useState(false);
    const [recurEndDate, setRecurEndDate] = useState(moment());
    const [dragWarning, setDragWarning] = useState(false);
    const [editing, setEditing] = useState(false);

    const calRef = useRef()
    
    useEffect( ()=> {
      getFamily()
      getEvents()
    },[])

    useEffect( ()=> {
      let tmp = masterevents
      if (masterevents != null) {
        for (let i = 0; i < tmp.length; i++) {
          const rgb = tmp[i].color.replace(/[^\d,]/g, '').split(',')
          const brightness = Math.round(((Number(rgb[0]) * 299) +
                      (Number(rgb[1]) * 587) +
                      (Number(rgb[2]) * 114)) / 1000);
          tmp[i].textColor = (brightness > 125) ? 'black' : 'white';
          tmp[i].color = tmp[i].color.replace('rgb','rgba').replace(')',',0.5)')
        }
      }
      setEvents(tmp)
    },[masterevents])

    useEffect( ()=> {
      const renderedList = family?.map((member)=>{
        return (
          <MenuItem key={member.id} value={member.name} colorid={member.color} id={member.id} ><span style={{ color: `${member.color}`, marginRight: '7px', fontSize: '18px'}}>{`\u25CF`}</span><span>{`${member.name}`}</span></MenuItem>
        )
      })
      renderedList?.push((<MenuItem key="999" colorid={"rgb(0,0,0)"} value={"All Calendars"} id={999}><span style={{ color: `black`, marginRight: '7px', fontSize: '18px'}}>{`\u25CF`}</span><span>All Calendars</span></MenuItem>))
      setFilterList(renderedList)
    },[family])

    useEffect( ()=> {
      // console.log(openaddevent)
    },[openaddevent])

    const addEvent = async (event) => {
        
        event.preventDefault()
        setOpenaddevent(false)

        const x = document.querySelector('[name="text1"]').value
        // console.log(x)
        const y = document.querySelector('[name="startdate"]').value
        const z = document.querySelector('[name="enddate"]').value
        const st = document.querySelector('[name="starttime"]').value
        const et = document.querySelector('[name="endtime"]').value
        const ad = allday
        const eel = document.querySelector('[name="loc1"]').value
        const een = document.querySelector('[name="notes1"]').value
        let recurringEvent = false;
        let recurringDays = []
        let recurringDays2 = []
        if (recurYearCheck) {
          recurringEvent = true
        }
        if (recurWeekCheck) {
          const sun = sunday
          const mon = monday
          const tues = tuesday
          const wed = wednesday
          const thurs = thursday
          const fri = friday
          const sat = saturday
          if (sun | mon | tues | wed | thurs | fri | sat) {
            recurringEvent = true
            if (sun) {
              recurringDays.push('su')
            }
            if (mon) {
              recurringDays.push('mo')
            } 
            if (tues) {
              recurringDays.push('tu')
            }
            if (wed) {
              recurringDays.push('we')
            }
            if (thurs) {
              recurringDays.push('th')
            }
            if (fri) {
              recurringDays.push('fr')
            }
            if (sat) {
              recurringDays.push('sa')
            }
          }
        }
        let n = 1
        if (recurDayCheck) {
          const sun2 = sunday2
          const mon2 = monday2
          const tues2 = tuesday2
          const wed2 = wednesday2
          const thurs2 = thursday2
          const fri2 = friday2
          const sat2 = saturday2
          
          if (recurFreq === 'first') {
            n=1
          } else if (recurFreq === 'second') {
            n=2
          } else if (recurFreq === 'third') {
            n=3
          } else if (recurFreq === 'fourth') {
            n=4
          } else if (recurFreq === 'last') {
            n=-1
          }
          if (sun2 | mon2 | tues2 | wed2 | thurs2 | fri2 | sat2) {
            recurringEvent = true
            if (sun2) {
              recurringDays2.push("su")
            }
            if (mon2) {
              recurringDays2.push("mo")
            } 
            if (tues2) {
              recurringDays2.push("tu")
            }
            if (wed2) {
              recurringDays2.push("we")
            }
            if (thurs2) {
              recurringDays2.push("th")
            }
            if (fri2) {
              recurringDays2.push("fr")
            }
            if (sat2) {
              recurringDays2.push("sa")
            }
          }
          
        }
        const start = moment(`${y} ${st}`)._d
        // const end = moment(et)._d
        let end = moment()._d
        if (allday) {
          end = moment(`${z} ${et}`).add(1,'day')._d
        } else {
          end = moment(`${z} ${et}`)._d
        }
        const title = x
        setEventtitle(title)

        const t  = moment(`${z} ${et}`).diff(moment(`${y} ${st}`),'minutes');
        let h = Math.floor(t / 60)
        let h2 = h.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
        let m2 = ('00'+(t  % 60)).slice(-2)
        const duration=h2+':'+m2

        let tmp = {}
        if (recurringEvent) {
          // setEvents([
            // ...events,
            if (recurWeekCheck) {
              tmp = {
                start: start,
                end: end,
                title: title,
                id: editing ? eventID : uuid(),
                calid: eventCalId,
                color: eventColor,
                allDay: allday,
                name: eventFamName,
                location: eel,
                notes: een,
                duration: duration,
                editable: false,
                rrule: {
                  freq: 'weekly',
                  interval: Number(rf),
                  byweekday: recurringDays,
                  dtstart: moment(start).format('YYYY-MM-DDTHH:mm'),
                  until: (moment(`${start}`).add(Number(rd),'weeks').format('YYYY-MM-DDThh:mm'))
                }
              }
            } else if (recurYearCheck) {
              const mm = parseInt(y.split('/')[0])
              const dd = parseInt(y.split('/')[1])
              tmp = {
                start: start,
                end: end,
                title: title,
                id: editing ? eventID : uuid(),
                calid: eventCalId,
                color: eventColor,
                allDay: allday,
                name: eventFamName,
                location: eel,
                notes: een,
                duration: allday ? null : duration,
                rrule: {
                  freq: 'yearly',
                  interval: 1,
                  // byweekday: recurringDays,
                  dtstart: moment(start).format('YYYY-MM-DDTHH:mm'),
                  until: allday ? null : (moment(`${start}`).add(Number(rd),'weeks').format('YYYY-MM-DDThh:mm')),
                  bymonth: mm,
                  bymonthday: dd
                }
              }
            }  else if (recurDayCheck) {
              const mm = parseInt(y.split('/')[0])
              const dd = parseInt(y.split('/')[1])
              tmp = {
                start: start,
                end: end,
                title: title,
                id: editing ? eventID : uuid(),
                calid: eventCalId,
                color: eventColor,
                allDay: allday,
                name: eventFamName,
                location: eel,
                notes: een,
                duration: allday ? null : duration,
                editable: false,
                rrule: {
                  freq: 'monthly',
                  interval: 1,
                  byweekday: recurringDays2,
                  bysetpos: n,
                  dtstart: moment(start).format('YYYY-MM-DDTHH:mm'),
                  until: allday ? null : (moment(`${recurEndDate}`).add(1,'day').format('YYYY-MM-DDThh:mm')),
                  // bymonth: mm,
                  // bymonthday: dd
                }
              }
            }
          // ]);
        } else {
          // setEvents([
            // ...events,
            if (moment(end).isBefore(moment(start))) {
              const t =  `${moment(start).month()+1}/${moment(start).date()}/${moment(start).year()}`
              end=moment(`${t} ${et}`)
            }
            tmp = {
              start: start,
              end: end,
              title: title,
              id: editing ? eventID : uuid(),
              name: eventFamName,
              calid: eventCalId,
              color: eventColor,
              allDay: allday,
              location: eel,
              notes: een,
            }
          // ]);
        }

        // console.log(tmp)

        if (editing) {
          await editCalEvent(tmp)
        } else {
          await addEventToMDB(tmp)
        }
        await getEvents()
        

        // console.log("\\",recurEndDate)
        // console.log(recurDayCheck)
        // console.log(sunday2,monday2,tuesday2,wednesday2,thursday2,friday2,saturday2)

        setEventFamName('')
        setFilterName("")
        setFilterColor("")
        const s = document.getElementById("demo-simple-select")
        s.blur()
        setAllday(false)
        setSunday(false)
        setMonday(false)
        setTuesday(false)
        setWednesday(false)
        setThursday(false)
        setFriday(false)
        setSaturday(false)
        setSunday2(false)
        setMonday2(false)
        setTuesday2(false)
        setWednesday2(false)
        setThursday2(false)
        setFriday2(false)
        setSaturday2(false)
        setShowRecurring(false)
        setChecked(false)
        setRecurYearCheck(false)
        setRecurWeekCheck(false)
        setRecurDayCheck(false)
        setRecurFreq('first')
        setRecurEndDate()
        setEditing(false)
        setEventtitle('')
        setEventnotes('')
        setEventlocation('')
        setEventid('')
    }

    const handleSelect = (info) => {
        // console.log(info)
        const x = new Date(info.start).toISOString()
        const y = new Date(info.end).toISOString()
        // console.log(Date(info.end))
        const numdays = (new Date(info.end) - new Date(info.start))/1000/60/60/24
        // console.log(numdays)
        if (numdays > 1) {
          setAllday(true)
          setChecked(true);
        }
        setEventStartDate(moment(x))
        setEventStartTime(moment(moment()))
        setEventEndDate(moment(y).subtract(1,'minutes'))
        setEventEndTime(moment(moment()).add(60,'minutes'))
        setOpenaddevent(true)
        // console.log(info)
      };

      const HalfHourMeeting = (event) => {
        const sd = document.querySelector('[name="startdate"]').value
        const st = document.querySelector('[name="starttime"]').value
        const et = moment(`${sd} ${st}`).add(30,'minutes')
        setEventEndTime(moment(et))
        event.target.blur()
      }

      const OneHourMeeting = (event) => {
        const sd = document.querySelector('[name="startdate"]').value
        const st = document.querySelector('[name="starttime"]').value
        const et = moment(`${sd} ${st}`).add(60,'minutes')
        setEventEndTime(moment(et))
        event.target.blur()
      }

      const TwoHourMeeting = (event) => {
        const sd = document.querySelector('[name="startdate"]').value
        const st = document.querySelector('[name="starttime"]').value
        const et = moment(`${sd} ${st}`).add(120,'minutes')
        setEventEndTime(moment(et))
        event.target.blur()
      }

      const closePicker1 = () => {
        let x = document.getElementById("startdatepicker")
        setTimeout(() => {
          x?.blur();
        }, 1);
      }
      const closePicker2 = () => {
        let x = document.getElementById("starttimepicker")
        setTimeout(() => {
          x?.blur();
        }, 1);
      }
      const closePicker3 = () => {
        let x = document.getElementById("enddatepicker")
        setTimeout(() => {
          x?.blur();
        }, 1);
      }
      const closePicker4 = () => {
        let x = document.getElementById("endtimepicker")
        setTimeout(() => {
          x?.blur();
        }, 1);
      }
      const closePicker5 = () => {
        let x = document.getElementById("endrecurpicker")
        setTimeout(() => {
          x?.blur();
        }, 1);
      }

      useEffect(()=>{
        // console.log(events)
      },[events])

      const handleDrag = async (info) => {
        let check = false
        const pos = events
        for (var idx in pos) {
          let check1 = pos[idx]['id'] ===  info.oldEvent._def.publicId
          if (check1) {
            if (pos[idx]['rrule']) {
              if (pos[idx]['rrule']['freq'] === 'weekly'){
                check = true
              }
              if (pos[idx]['rrule']['freq'] === 'monthly'){
                check = true
              }
            }
          }
        }
        if (check) {
          calRef.current.render()
          setDragWarning(true)
          
        } else {
          const start = moment(`${info.event._instance.range.start}`)._d
          const end = moment(`${info.event._instance.range.end}`)._d
          const obj = {id: info.oldEvent._def.publicId, start: start, end: end}
          await editEvent(obj)
        }
      }

      const handleAddEventClose = () =>{ 
        // for (var i = 0; i < 20000; i++) {
          // console.log(i);
        // }
        // setOpenaddevent(false)
        setEditing(false)
        setEventFamName('')
        setFilterName("")
        setFilterColor("")
        const s = document.getElementById("demo-simple-select")
        s.blur()
        setAllday(false)
        setSunday(false)
        setMonday(false)
        setTuesday(false)
        setWednesday(false)
        setThursday(false)
        setFriday(false)
        setSaturday(false)
        setSunday2(false)
        setMonday2(false)
        setTuesday2(false)
        setWednesday2(false)
        setThursday2(false)
        setFriday2(false)
        setSaturday2(false)
        setShowRecurring(false)
        setChecked(false)
        setRecurYearCheck(false)
        setRecurWeekCheck(false)
        setRecurDayCheck(false)
        setRecurFreq('first')
        setRecurEndDate()
        setEditing(false)
        setEventtitle('')
        setEventnotes('')
        setEventlocation('')
        setEventid('')
      }

      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth < 800 ? '90%' : 450,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth < 800 ? '95%' : 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };

      const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };

      const handleEventStartDateChange = (event) => {
        // console.log(event)
        // console.log(eventStartDate)
        // setEventStartDate(event)
      }

      const handleEventEndDateChange = (event) => {
        // console.log(event)
        // console.log(eventStartDate)
        // setEventStartDate(event)
      }

      const handleEventClick = (info) => {
        // console.log('&&',info)
        setViewEventTitle(info.event.title)
        setViewEventStart(info.event.startStr)
        setViewEventEnd(info.event.endStr)
        setViewEventAllday(info.event.allDay)
        setViewEventLocation(info.event.extendedProps.location)
        setViewEventNotes(info.event.extendedProps.notes)
        setViewEventId(info.event.id)
        setViewEventCalId(info.event.extendedProps.calid)
        setViewEventColor(info.event.backgroundColor)
        setViewEventName(info.event.extendedProps.name)
        setOpendetails(true)
      }

      const handleDetailsClose = () => setOpendetails(false);
      const handleRecurringClose = () => setOpenrecurring(false);
      const handleDragClose = () => setDragWarning(false);

      const handleNameChange = (event,child) => {
        // console.log(event.target.value);
        // console.log(child)
        setEventFamName(event.target.value)
        setEventColor(child.props.memcol)
        setEventCalId(child.props.calid)
        // console.log(event.target.value)
        // console.log(tmp[1])
      };

      useEffect(()=>{
        // console.log('*',showRecurring)
      },[showRecurring])
      

      const handleFilterChange = (event,child) => {
        
        setFilterName(event.target.value);
        // console.log("Filter changed to: ",child.props.id,", ",event.target.value)
        setFilterColor(child.props.colorid)
        // apply filter logic here
        let filteredEvents = []
        const tmp = masterevents
        if (child.props.id === 999) {
          filteredEvents = tmp
        } else {
          filteredEvents =tmp.filter((event) => event.calid === child.props.id);
        }
        setEvents(filteredEvents)
      };

      const handleAlldayChange = (event) => {
        // console.log(event)
        setChecked(event);
        setAllday(event)
      };

      const HandleRecurringClick = (event) => {
        // setShowRecurring(showRecurring => !showRecurring)
        setOpenrecurring(true)
        event.target.blur()
      }

      const cancelAdd = () => {
        setEditing(false)
        setEventFamName('')
        setFilterName("")
        setFilterColor("")
        const s = document.getElementById("demo-simple-select")
        s.blur()
        setAllday(false)
        setSunday(false)
        setMonday(false)
        setTuesday(false)
        setWednesday(false)
        setThursday(false)
        setFriday(false)
        setSaturday(false)
        setSunday2(false)
        setMonday2(false)
        setTuesday2(false)
        setWednesday2(false)
        setThursday2(false)
        setFriday2(false)
        setSaturday2(false)
        setShowRecurring(false)
        setChecked(false)
        setRecurYearCheck(false)
        setRecurWeekCheck(false)
        setRecurDayCheck(false)
        setRecurFreq('first')
        setRecurEndDate()
        setEditing(false)
        setEventtitle('')
        setEventnotes('')
        setEventlocation('')
        setEventid('')
        setOpenaddevent(false)
      }

      const closeViewEvent = (event) => {
        setOpendetails(false)
        event.target.blur()
      }

      const closeDragEvent = (event) => {
        setDragWarning(false)
        event.target.blur()
      }

      const deleteEvent = async (event,msgid, calid) => {
        await removeEvent({msgid: msgid, calid: calid})
        await getEvents()
        setOpendetails(false)
      }

      const handleFreqChange = (event) => {
        setRecurFreq(event.target.value)
        if (event.target.value === 'year') {
          // setRecurYearCheck(true)
        } else {
          // setRecurYearCheck(false)
        }
      }

      const recurDayCheckChange = (event) => {
        setRecurDayCheck(event.target.checked)
        if (event.target.checked) {
          setRecurYearCheck(false)
          setRecurWeekCheck(false)
        }
      }

      const recurWeekCheckChange = (event) => {
        setRecurWeekCheck(event.target.checked)
        if (event.target.checked) {
          setRecurYearCheck(false)
          setRecurDayCheck(false)
        }
      }

      const recurYearCheckChange = (event) => {
        setRecurYearCheck(event.target.checked)
        if (event.target.checked) {
          setRecurWeekCheck(false)
          setRecurDayCheck(false)
        }
      }

      useEffect(()=>{
        // console.log('*',recurYearCheck)
      },[recurYearCheck])

      const editCalEventLocal = (event, details) => {
        setOpendetails(false)
        // console.log(details)
        let tmp = {}
        for (let i = 0; i < masterevents.length; i++) {
          if (masterevents[i].id === details.viewEventId) {
            tmp=masterevents[i]
            setEventid(details.viewEventId)
            setEditing(true)
          }
        }
        //preload values
        // console.log(tmp)
        setEventFamName(tmp.name)
        setChecked(tmp.allDay)
        setAllday(tmp.allDay)
        setEventtitle(tmp.title)
        setEventlocation(tmp.location)
        setEventnotes(tmp.notes)
        setEventStartDate(moment(tmp.start))
        setEventStartTime(moment(tmp.start))
        setEventEndDate(moment(tmp.end))
        setEventEndTime(moment(tmp.end))
        setEventCalId(details.viewEventCalId)
        setEventColor(tmp.color.replace('rgba','rgb').replace(',0.5)',')'))
        if ('rrule' in tmp) {
          if (tmp.rrule.freq === 'monthly') {
            setRecurDayCheck(true)
            for (var i = 0; i < tmp.rrule.byweekday.length; i++) { 
              if (tmp.rrule.byweekday[i]==='su'){
                setSunday2(true)
              } else if (tmp.rrule.byweekday[i]==='mo') {
                setMonday2(true)
              } else if (tmp.rrule.byweekday[i]==='tu') {
                setTuesday2(true)
              } else if (tmp.rrule.byweekday[i]==='we') {
                setWednesday2(true)
              } else if (tmp.rrule.byweekday[i]==='th') {
                setThursday2(true)
              } else if (tmp.rrule.byweekday[i]==='fr') {
                setFriday2(true)
              } else if (tmp.rrule.byweekday[i]==='sa') {
                setSaturday2(true)
              }
            }
            setRecurEndDate(moment(tmp.rrule.until).subtract(1,'day'))
            setRecurFreq()
            if (tmp.rrule.bysetpos === '1' | tmp.rrule.bysetpos === 1) {
              setRecurFreq('first')
            } else if (tmp.rrule.bysetpos === '2' | tmp.rrule.bysetpos === 2) {
              setRecurFreq('second')
            } else if (tmp.rrule.bysetpos === '3' | tmp.rrule.bysetpos === 3) {
              setRecurFreq('third')
            } else if (tmp.rrule.bysetpos === '4' | tmp.rrule.bysetpos === 4) {
              setRecurFreq('fourth')
            } else if (tmp.rrule.bysetpos === '-1' | tmp.rrule.bysetpos === -1) {
              setRecurFreq('last')
            }
          } else if (tmp.rrule.freq === 'weekly') {
            setRecurWeekCheck(true)
            for (var i = 0; i < tmp.rrule.byweekday.length; i++) { 
              if (tmp.rrule.byweekday[i]==='su'){
                setSunday(true)
              } else if (tmp.rrule.byweekday[i]==='mo') {
                setMonday(true)
              } else if (tmp.rrule.byweekday[i]==='tu') {
                setTuesday(true)
              } else if (tmp.rrule.byweekday[i]==='we') {
                setWednesday(true)
              } else if (tmp.rrule.byweekday[i]==='th') {
                setThursday(true)
              } else if (tmp.rrule.byweekday[i]==='fr') {
                setFriday(true)
              } else if (tmp.rrule.byweekday[i]==='sa') {
                setSaturday(true)
              }
            }
            setRf(tmp.rrule.interval)
            setRd(moment(`${tmp.rrule.until}`).diff(moment(tmp.start),'weeks'))
          } else if (tmp.rrule.freq === 'yearly') {
            setRecurYearCheck(true)
          }
        }
        setOpenaddevent(true)
      }

        return (
                <div style={{padding: '0 10px'}}>
                  {/* <div >
                  <AvatarGroup style={{ justifyContent: "center", display: "flex" }} max={4} sx={{'& .MuiAvatar-root': { width: 56, height: 56 }}}>
                    {family ? family.map((member) => {
                      return (
                        <Avatar key={member.name} alt={member.name} src="" sx={{ bgcolor: `${member.color}`  }}>{member.initials}</Avatar>
                      )
                    }) : null}
                  </AvatarGroup>
                  </div> */}
                  <div style={{marginTop: '30px', height: '5vh'}}>
                  <Box sx={{ minWidth: 120 }} >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" sx={{'&.MuiInputLabel-shrink':{color: filterColor}}}>Choose a calendar</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterName}
                        label="Choose a calendar"
                        onChange={handleFilterChange}
                        style={{borderRadius: '15px'}}
                        sx={{
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: filterColor,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: filterColor,
                          },
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: filterColor,
                          }
                        }}
                      >
                        {filterList}
                      </Select>
                    </FormControl>
                  </Box>
                  </div>
                  <StyleWrapper style={{marginBottom: '20px'}}>
                  <FullCalendar
                      headerToolbar={{
                          start: "prev today next",
                          center: 'title',
                          end: "dayGridMonth timeGridWeek timeGridDay",
                        }}
                      ref = {calRef}
                      editable
                      rerenderDelay={100}
                      selectable
                      // selectLongPressDelay={0}
                      nowIndicator={true}
                      events={events}
                      height='80vh'
                      slotEventOverlap={false}
                      dayMaxEvents
                      select={handleSelect}
                      plugins={[ dayGridPlugin,timeGridPlugin,interactionPlugin,rrulePlugin ]}
                      eventDurationEditable
                      eventClick={handleEventClick}
                      // views={["dayGridMonth", "timeGridWeek","timeGridDay"]}
                      views={{
                        dayGrid: {
                          // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
                          selectLongPressDelay: 0
                        },
                        timeGrid: {
                          // options apply to timeGridWeek and timeGridDay views
                          selectLongPressDelay: 500
                        }
                      }}
                      initialView="dayGridMonth"
                      eventDrop={handleDrag}
                      // eventContent={(info) => <EventItem info={info} />}
                  />

                  </StyleWrapper>

                    <Modal open={openaddevent} onClose={handleAddEventClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    
                      <Box sx={style}>
                        <form noValidate autoComplete="off" onSubmit={(event) => addEvent(event)} >
                          <FormControl sx={{ width: '100%' }} >
                            
                            <FormControl fullWidth style={{paddingBottom: '7px'}}>
                              <InputLabel id="demo-simple-select-label">Who's Calendar?</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={eventFamName}
                                label="Who's Calendar?"
                                onChange={handleNameChange}
                              >
                                {
                                  family?.map((member)=>{
                                    return(
                                      <MenuItem key={member.id} calid={member.id} value={member.name} memcol={member.color}>{member.name}</MenuItem>
                                    )
                                  })
                                }
                              </Select>
                            </FormControl>
                            <TextField sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}} onChange={(event)=>setEventtitle(event.target.value)} value={eventTitle} variant="standard" name='text1' placeholder="Title" style={{marginTop: '7px', marginBottom: '7px'}}/>
                            <div style={{display: 'inline-flex', flexDirection: 'row', marginTop: '7px', marginBottom: '7px'}}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  label="Start Date"
                                  name="startdate"
                                  style={{paddingLeft: '5px'}}
                                  value={eventStartDate}
                                  // defaultValue={eventStartDate}
                                  onClose={()=>closePicker1()}
                                  onChange={handleEventStartDateChange}
                                  renderInput={(params) => <TextField {...params}/>}
                                  slotProps={{openPickerButton:{id: "startdatepicker"}, textField: {variant: "standard",sx:{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}}}
                                />
                                <div style={{margin:'0 7px'}}></div>
                                <TimePicker name="starttime" onClose={()=>closePicker2()} disabled={checked} label="Start Time" value={eventStartTime} defaultValue={eventStartTime} timeSteps={{ hours: 1, minutes: 5 }} slotProps={{openPickerButton:{id: "starttimepicker"},textField: {variant: "standard",sx:{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}}}/>
                              </LocalizationProvider>
                            </div>
                            <div style={{display: 'inline-flex', flexDirection: 'row', paddingTop: '7px', paddingBottom: '7px', alignItems: "center"}}>
                              <Switch name="adswitch" onColor='#1a76d3' onChange={handleAlldayChange} checked={checked} checkedIcon={false} uncheckedIcon={false} height={21} width={42}/>
                              <span style={{marginLeft: '2px'}}>All day</span>
                              <div style={{order: 2, marginLeft: "auto"}}>
                                <Button variant="contained" color="primary" onClick={(event)=>HalfHourMeeting(event)} style={{margin: "0 2px",padding: window.innerWidth > 800 ? '6px 16px' : '6px 3px', minWidth: window.innerWidth > 800 ? '64px' : '45px'}}>+0:30</Button>
                                <Button variant="contained" color="primary" onClick={(event)=>OneHourMeeting(event)} style={{margin: "0 2px",padding: window.innerWidth > 800 ? '6px 16px' : '6px 3px', minWidth: window.innerWidth > 800 ? '64px' : '45px'}}>+1:00</Button>
                                <Button variant="contained" color="primary" onClick={(event)=>TwoHourMeeting(event)} style={{margin: "0 2px",padding: window.innerWidth > 800 ? '6px 16px' : '6px 3px', minWidth: window.innerWidth > 800 ? '64px' : '45px'}}>+2:00</Button>
                              </div>
                            </div>
                            <div style={{display: 'inline-flex', flexDirection: 'row', paddingTop: '7px', paddingBottom: '7px'}}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  label="End Date"
                                  name="enddate"
                                  value={eventEndDate}
                                  // defaultValue={eventStartDate}
                                  onClose={()=>closePicker3()}
                                  onChange={handleEventEndDateChange}
                                  renderInput={(params) => <TextField {...params} />}
                                  // sx={{"& .MuiOutlinedInput-root": {paddingLeft: '8px'}}}
                                  slotProps={{openPickerButton:{id: "enddatepicker"}, textField: {variant: "standard",sx:{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}}}
                                />
                                <div style={{margin:'0 7px'}}></div>
                                <TimePicker name="endtime" onClose={()=>closePicker4()} onChange={setEventEndTime} disabled={checked} label="End Time" slotProps={{openPickerButton:{id: "endtimepicker"}, textField: {variant: "standard",sx:{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}}} defaultValue={eventEndTime} value={eventEndTime} timeSteps={{ hours: 1, minutes: 5 }}/>
                              </LocalizationProvider>
                            </div>
                            <TextField name='loc1' sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}  variant="standard" placeholder="Location" onChange={(event)=>setEventlocation(event.target.value)} value={eventLocation} style={{marginTop: '7px', marginBottom: '7px'}}/>
                            <TextField name='notes1' sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}  variant="standard" placeholder="Notes" onChange={(event)=>setEventnotes(event.target.value)} value={eventNotes} style={{marginTop: '7px', marginBottom: '7px'}}/>
                            
                            <Button onClick={(event)=>HandleRecurringClick(event)} style={{margin: "0 2px",textTransform: 'none'}}>{showRecurring ? "Recurring \u2227" : "Recurring \u2228"}</Button>
                          </FormControl>
                          <div style={{paddingTop: '15px'}}>
                            <Button variant="outlined" onClick={()=>cancelAdd()} color="error">Cancel</Button>
                            <Button variant='contained' type="submit" style={{float: 'right'}}>Add</Button>
                          </div>
                        </form>
                      </Box>
                    </Modal>





                    <Modal open={opendetails} onClose={handleDetailsClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                      <Box sx={style2} style={{padding: '8px'}}>
                        <div style={{padding: '8px', border: `2px solid ${viewEventColor}`}}>
                          <Button variant="standard" onClick={(event)=>closeViewEvent(event)} style={{position: 'absolute', top: '7px', right: '2px'}} sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '5px', border: 'none'}} ><ClearIcon sx={{ fontSize: 32, color: 'black' }}/></Button>
                          <div style={{display: 'flex', justifyContent: "center"}}>
                            <span style={{fontSize: '36px',textAlign: 'center',fontFamily: projectFont}}>{viewEventTitle}</span>
                          </div>
                          <div style={{display: 'flex', justifyContent: "center", marginBottom: '10px'}}>
                            <span style={{fontSize: '12px'}}>({viewEventName})</span>
                          </div>
                          {viewEventEnd !== "" ?
                            <div style={{marginBottom: '10px'}}>
                              <div>
                                <span>From: {viewEventAllday ? moment(viewEventStart).format('MMMM Do YYYY') : moment(viewEventStart).format('MMMM Do YYYY, h:mm a')}</span>
                              </div>
                              <div>
                                <span>Until: {viewEventAllday ? moment(viewEventEnd).subtract(1,'day').format('MMMM Do YYYY') : moment(viewEventEnd).format('MMMM Do YYYY, h:mm a')}</span>
                              </div>
                            </div>
                          : 
                            <div style={{marginBottom: '10px'}}>
                              <span>Date: {moment(viewEventStart).format('MMMM Do YYYY, h:mm a')}</span>
                            </div>}
                          <div style={{marginBottom: '10px'}}>
                            <span>Location: {viewEventLocation}</span>
                          </div>
                          <div style={{marginBottom: '30px'}}>
                            <span>Notes: {viewEventNotes}</span>
                          </div>
                          <div style={{paddingTop: '15px', marginBottom: '30px'}}>
                            {/* <Button variant="outlined"  onClick={(event)=>deleteEvent(event,viewEventId, viewEventCalId)} color="error">Delete</Button> */}
                            <Button variant="contained" onClick={(event)=>deleteEvent(event,viewEventId, viewEventCalId)} color='error' style={{marginLeft: '50px'}} sx={{fontSize: '30px', height: '50px', width: '50px', minWidth: '50px', border: 'none', padding: '10px'}} ><DeleteIcon sx={{ fontSize: 32 }}/></Button>
                            {/* <Button variant='contained' onClick={(event)=>closeViewEvent(event)} style={{float: 'right', marginLeft: '5px'}}>Close</Button> */}
                            {/* <Button variant='contained' onClick={(event)=>editCalEventLocal(event,{viewEventId,viewEventCalId})} style={{float: 'right'}}>Edit</Button> */}
                            <Button variant="contained" onClick={(event)=>editCalEventLocal(event,{viewEventId,viewEventCalId})} style={{float: 'right', marginRight: '50px'}} sx={{fontSize: '30px', height: '50px', width: '50px', minWidth: '50px', border: 'none', padding: '10px'}} ><EditIcon sx={{ fontSize: 32 }}/></Button>
                          </div>
                        </div>
                      </Box>
                    </Modal>



                    <Modal open={openrecurring} onClose={handleRecurringClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                      <Stack sx={style3} style={{padding: '8px'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                              <FormControlLabel style={{margin: "0", paddingRight: '20px'}} name="yearcheck" checked={recurYearCheck} onChange={(event)=>recurYearCheckChange(event)} control={<Checkbox />}  />
                              <div style={{pointerEvents: recurYearCheck ? 'auto' : 'none',opacity: recurYearCheck ? 1 : 0.4}}>
                                <Typography style={{paddingRight: '2px'}}>Occurs every year on the same day.</Typography>
                              </div>
                            </div>
                            <Divider style={{marginTop: '8px', marginBottom: '8px'}}/>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                              <FormControlLabel style={{margin: "0", paddingRight: '20px'}} name="weekcheck" checked={recurWeekCheck} onChange={(event)=>recurWeekCheckChange(event)} control={<Checkbox />}  />
                              <div style={{pointerEvents: recurWeekCheck ? 'auto' : 'none',opacity: recurWeekCheck ? 1 : 0.4}}>
                                Occurs every:
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '7px'}}>
                                  <FormControlLabel style={{margin: "0"}} name="sundaycheck" checked={sunday} onChange={(event)=>setSunday(event.target.checked)} control={<Checkbox />} label="S" labelPlacement="top" />
                                  <FormControlLabel style={{margin: "0"}} name="mondaycheck" checked={monday} onChange={(event)=>setMonday(event.target.checked)} control={<Checkbox />} label="M" labelPlacement="top" />
                                  <FormControlLabel style={{margin: "0"}} name="tuesdaycheck" checked={tuesday} onChange={(event)=>setTuesday(event.target.checked)} control={<Checkbox />} label="T" labelPlacement="top" />
                                  <FormControlLabel style={{margin: "0"}} name="wednesdaycheck" checked={wednesday} onChange={(event)=>setWednesday(event.target.checked)} control={<Checkbox />} label="W" labelPlacement="top" />
                                  <FormControlLabel style={{margin: "0"}} name="thursdaycheck" checked={thursday} onChange={(event)=>setThursday(event.target.checked)} control={<Checkbox />} label="H" labelPlacement="top" />
                                  <FormControlLabel style={{margin: "0"}} name="fridaycheck" checked={friday} onChange={(event)=>setFriday(event.target.checked)} control={<Checkbox />} label="F" labelPlacement="top" />
                                  <FormControlLabel style={{margin: "0"}} name="saturdaycheck" checked={saturday} onChange={(event)=>setSaturday(event.target.checked)} control={<Checkbox />} label="S" labelPlacement="top" />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'center'}}>
                                    <Typography style={{paddingRight: '2px'}}>every</Typography>
                                    <TextField name="recurfreq" value={rf} onChange={(event)=>setRf(event.target.value)} inputProps={{min: 0, style: { textAlign: 'center' }}}  type="number" variant="standard" placeholder='1' sx={{ "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {display: "none"},"& input[type=number]": {MozAppearance: "textfield"},width: '30px',".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}></TextField>
                                    <Typography style={{paddingLeft: '2px',paddingRight: '2px'}}>{"week(s) for the next"}</Typography>
                                    <TextField name="recurweeks" value={rd } onChange={(event)=>setRd(event.target.value)} inputProps={{min: 0, style: { textAlign: 'center' }}}  type="number" variant="standard" placeholder='1' sx={{ "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {display: "none"},"& input[type=number]": {MozAppearance: "textfield"},width: '30px',".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}></TextField>
                                    <Typography style={{paddingLeft: '2px'}}>{"week(s)"}</Typography>
                                </div>
                              </div>
                            </div>
                            <Divider style={{marginTop: '8px', marginBottom: '8px'}}/>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                              <FormControlLabel style={{margin: "0", paddingRight: '20px'}} name="daycheck" checked={recurDayCheck} onChange={(event)=>recurDayCheckChange(event)} control={<Checkbox />}  />
                              <div style={{pointerEvents: recurDayCheck ? 'auto' : 'none',opacity: recurDayCheck ? 1 : 0.4}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                                  <Typography>Occurs on the </Typography> 
                                  <Box sx={{ minWidth: '75px' }} >
                                    <FormControl>
                                      <InputLabel shrink={false} id="demo-simple-select-label"></InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="freq-select"
                                        style={{padding: '0 !important'}}
                                        defaultValue={"first"}
                                        // inputProps={{padding: '0 !important', IconComponent: () => null }} // this part
                                        sx={{
                                          padding: "0 !important",
                                          boxShadow: "none",
                                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: 0 
                                            },
                                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: 0 
                                            },
                                        }}
                                        value={recurFreq}
                                        // label=""
                                        onChange={(event)=>handleFreqChange(event)}
                                        // style={{borderRadius: '15px'}}
                                      >
                                        <MenuItem value='first'>{"first"}</MenuItem>
                                        <MenuItem value='second'>{"second"}</MenuItem>
                                        <MenuItem value='third'>{"third"}</MenuItem>
                                        <MenuItem value='fourth'>{"fourth"}</MenuItem>
                                        <MenuItem value='last'>{"last"}</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '7px'}}>
                                    <FormControlLabel style={{margin: "0"}} name="sundaycheck2" checked={sunday2} onChange={(event)=>setSunday2(event.target.checked)} control={<Checkbox />} label="S" labelPlacement="top" />
                                    <FormControlLabel style={{margin: "0"}} name="mondaycheck2" checked={monday2} onChange={(event)=>setMonday2(event.target.checked)} control={<Checkbox />} label="M" labelPlacement="top" />
                                    <FormControlLabel style={{margin: "0"}} name="tuesdaycheck2" checked={tuesday2} onChange={(event)=>setTuesday2(event.target.checked)} control={<Checkbox />} label="T" labelPlacement="top" />
                                    <FormControlLabel style={{margin: "0"}} name="wednesdaycheck2" checked={wednesday2} onChange={(event)=>setWednesday2(event.target.checked)} control={<Checkbox />} label="W" labelPlacement="top" />
                                    <FormControlLabel style={{margin: "0"}} name="thursdaycheck2" checked={thursday2} onChange={(event)=>setThursday2(event.target.checked)} control={<Checkbox />} label="H" labelPlacement="top" />
                                    <FormControlLabel style={{margin: "0"}} name="fridaycheck2" checked={friday2} onChange={(event)=>setFriday2(event.target.checked)} control={<Checkbox />} label="F" labelPlacement="top" />
                                    <FormControlLabel style={{margin: "0"}} name="saturdaycheck2" checked={saturday2} onChange={(event)=>setSaturday2(event.target.checked)} control={<Checkbox />} label="S" labelPlacement="top" />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                                  <Typography>of each month until </Typography>
                                  <div style={{width: '130px', marginLeft: '12px'}}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <DatePicker
                                        label="End Date"
                                        name="endreccur"
                                        value={recurEndDate}
                                        // defaultValue={eventStartDate}
                                        onClose={()=>closePicker5()}
                                        onChange={setRecurEndDate}
                                        renderInput={(params) => <TextField {...params} />}
                                        // sx={{"& .MuiOutlinedInput-root": {paddingLeft: '8px'}}}
                                        slotProps={{openPickerButton:{id: "endrecurpicker"}, textField: {variant: "standard",sx:{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}}}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Button variant='contained' onClick={()=>setOpenrecurring(false)} style={{marginTop: '15px', marginLeft: 'auto', width: '100px'}}>Close</Button>
                      </Stack>
                    </Modal>


                    <Modal open={dragWarning} onClose={handleDragClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                      <Box sx={style2} style={{padding: '8px'}}>
                        <div style={{padding: '8px'}}>
                          <div style={{display: 'flex', justifyContent: "center"}}>
                            <span style={{fontSize: '36px',textAlign: 'center',fontFamily: projectFont}}>Sorry, you can't drag and drop weekly or monthly recurring events yet. Please delete the set and add it back with the correct dates.</span>
                          </div>
                          <div style={{paddingTop: '15px'}}>
                            <Button variant='contained' onClick={(event)=>closeDragEvent(event)} style={{float: 'right'}}>Close</Button>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                 
                </div>
               )
    

}

function mapStateToProps(state) {
    return {
            auth: state.auth, 
            family: state.family, 
            masterevents: state.masterevents
           }
}

export default withRouter(connect(mapStateToProps,{getFamily,getEvents,addEventToMDB,removeEvent,editEvent,editCalEvent})(Calendar))